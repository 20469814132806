var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-toolbar", {
        attrs: {
          title: "Applications",
          "view-option": _vm.viewOption,
          indicator: _vm.applications.length
        },
        on: {
          "update:viewOption": function($event) {
            _vm.viewOption = $event
          },
          "update:view-option": function($event) {
            _vm.viewOption = $event
          },
          refresh: _vm.refresh
        }
      }),
      Object.keys(_vm.pagination).length > 0
        ? _c(
            "application-list-paginator",
            _vm._b(
              {
                attrs: {
                  per_page: _vm.per_page,
                  current_page: _vm.current_page
                },
                on: {
                  "update:per_page": function($event) {
                    _vm.per_page = $event
                  },
                  "update:current_page": function($event) {
                    _vm.current_page = $event
                  }
                }
              },
              "application-list-paginator",
              _vm.pagination,
              false
            )
          )
        : _vm._e(),
      _c("v-divider"),
      _vm.loading
        ? _c("psi-content-loader", { attrs: { type: "circular" } })
        : _vm._e(),
      _c("application-list", {
        attrs: { applications: _vm.applications, screeners: _vm.screeners }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }