<template>
    <div>
        <psi-toolbar
            title="Applications"
            :view-option.sync="viewOption"
            :indicator="applications.length"
            @refresh="refresh"
        ></psi-toolbar>
        <application-list-paginator
            v-if="Object.keys(pagination).length > 0"
            :per_page.sync="per_page"
            :current_page.sync="current_page"
            v-bind="pagination"
        ></application-list-paginator>
        <v-divider></v-divider>
        <psi-content-loader v-if="loading" type="circular"></psi-content-loader>
        <application-list
            :applications="applications"
            :screeners="screeners"
        ></application-list>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "applications-list-wrapper",
    components: {
        ApplicationListPaginator: () =>
            import("./ApplicationListPaginator.vue"),
        ApplicationList: () => import("./ApplicationList"),
    },
    props: {},
    data() {
        return {
            viewOption: "list",
            per_page: this.$config("uploads.pagination.perPage"),
            current_page: 1, // start on page 1
        };
    },
    computed: {
        ...mapGetters("Uploads", [
            "applications",
            "pagination",
            "loading",
            "screeners",
        ]),
    },
    watch: {
        current_page() {
            this.refresh();
        },
        per_page() {
            // start at page 1 when changing per page counts
            this.current_page = 1;
            this.refresh();
        },
    },
    created() {},
    mounted() {
        this.refresh();
    },
    updated() {},
    detroyed() {},
    methods: {
        ...mapActions("Uploads", ["getApplications"]),
        refresh() {
            this.getApplications({
                page: this.current_page,
                perPage: this.per_page,
            });
        },
    },
};
</script>

<style scoped>
</style>