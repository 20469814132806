<template>
    <div id="page-upload-history">
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
        ></psi-page-title>
        <v-card class="mt-4">
            <v-card-text>
                <applications-list-wrapper></applications-list-wrapper>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import ApplicationsListWrapper from "../components/History/ApplicationsListWrapper.vue";
export default {
    name: "upload-history",
    components: { ApplicationsListWrapper },
    props: {},
    data() {
        return {
            page: {
                title: "Application Uploads",
                icon: "mdi-clipboard-plus-outline",
            },
            breadcrumbs: [
                {
                    text: "Home",
                    disabled: false,
                    to: "#",
                },
                {
                    text: "Uploads",
                    disabled: true,
                    to: "#",
                },
            ],
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>